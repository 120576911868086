import React from 'react';

import Layout from '../components/Layout';

import { container } from './404.module.scss';

export default function FourOhFour({ location }) {
  return (
    <Layout location={location}>
      {() => (
        <div className={container}>
          <h1>PAGE NOT FOUND :-(</h1>
        </div>
      )}
    </Layout>
  );
}
